let resumeData = {
  imagebaseurl: "http://josechirinos.com/",
  name: "Jose Chirinos",
  role: "Frontend and Mobile Developer",
  cellphone: "(+591) 79413052",
  email: "josealexcba@gmail.com",
  skypeid: "Your skypeid",
  roleDescription:
    "I like dabbling in various parts of frontend development and like to learn about new technologies, write technical articles or simply play games in my free time.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/jose-chirinos-0a62a6105",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "http://github.com/JoseChirinos",
      className: "fa fa-github",
    },
    {
      name: "skype",
      url: "http://twitter.com/JseChirinos",
      className: "fa fa-twitter",
    },
  ],
  aboutme:
    "I am Javascript Developer, Founder of the Javascript Sucre community and Co-Founder of the GDG Sucre community, passionate about web technology and graphic design.",
  address: "Bolivia",
  website: "",
  education: [
    {
      UniversityName: "Universidad San Francisco Xavier",
      specialization: "Ing. Sistemas",
      MonthOfPassing: "Mar",
      YearOfPassing: "∞",
      Achievements:
        "President of the Scientific Society of Systems and Telecommunications Engineering",
    },
    {
      UniversityName: "Itec Institute",
      specialization: "Graphic design",
      MonthOfPassing: "Jan",
      YearOfPassing: "2016",
      Achievements: "UI Design of Goomovil.com",
    },
  ],
  work: [
    {
      CompanyName: "EDteam - Peru",
      specialization: "Frontend Developer",
      MonthOfLeaving: "May",
      YearOfLeaving: "2020",
      Achievements: "Educational platform - ed.team",
    },
    {
      CompanyName: "Apphive Inc - México",
      specialization: "Javascript Developer",
      MonthOfLeaving: "Jun",
      YearOfLeaving: "2019",
      Achievements: "Create an App for Free No code app builder - apphive.io",
    },
    {
      CompanyName: "OpenRed - Bolivia",
      specialization: "FullStack Developer",
      MonthOfLeaving: "Dec",
      YearOfLeaving: "2017",
      Achievements: "Business Network of Bolivia - goomovil.com",
    },
    {
      CompanyName: "10Minds - Bolivia",
      specialization: "Frontend Developer",
      MonthOfLeaving: "Jan",
      YearOfLeaving: "2017",
      Achievements: "Sale of spare parts - elauxlio.com",
    },
  ],
  skillsDescription: "Frontend and Mobile developer",
  skills: [
    {
      skillname: "HTML5, CSS & JS",
      skillpower: "100%",
    },
    {
      skillname: "ReactJS & NextJS",
      skillpower: "100%",
    },
    {
      skillname: "Angular 10",
      skillpower: "70%",
    },
    {
      skillname: "VueJS 2",
      skillpower: "80%",
    },
    {
      skillname: "Firebase, Google Cloud, Google Maps",
      skillpower: "70%",
    },
    {
      skillname: "Flutter, ReactNative",
      skillpower: "70%",
    },
    {
      skillname: "Adobe Photoshop, Illustrator, After Effects, Sketch",
      skillpower: "80%",
    },
  ],
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project.jpg",
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.png",
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
  ],
  testimonials: [
    {
      description: "Excelente Trabajo - La App esta bien trabajada",
      name: "Jose Luis Coronado - (CEO) Openred Bolivia",
    },
  ],
}

export default resumeData
